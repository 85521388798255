<template>
    <div class="page-body wrapper-fixed">

        <div class="login-control d-flex">
            <language-switcher></language-switcher>
        </div>
        <div class="col-md-6 login-thumbnail">
            <a href="#" class="site-logo">
                <site-logo-s-v-g/>
            </a>
            <img src="/img/Repeat Grid 3.png" alt="" class="login-page__background"/>
        </div>
        <div class="col-md-6 login-content">
            <div class="text-center">
                <h4 class="page-title">Get Discovered by Top Employer and Grab Your Dream Job.</h4>
                <p class="page-description">Find your dream jobs through the best applicant tracking system.</p>
            </div>
            <div class="login-box">
                <h2 class="login-box__title">Please sign up</h2>
                <div class="signup-message text-center">Already have an account?
                    <router-link :to="{name : 'login'}" class="color-primary">Sign In</router-link>
                </div>
                <div class="alert fade show alert-danger" role="alert" v-if="errorMessage.length>0">
                    <button type="button" class="close" @click="hideErrorAlert()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {{ errorMessage }}
                </div>
                <form class="login-form">
                    <div class="form-group">
                        <label>Email Address</label>
                        <input class="form-control" :class="{ 'has-error': errors.hasOwnProperty('email') && errors.email.length }" type="email"
                               v-model="form.email" placeholder="youremail@gmail.com" disabled/>
                        <error-message :message="errors.email"/>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="text-capitalize">{{ $t("First name") }}</label>
                                <input class="form-control" :class="{ 'has-error':errors.hasOwnProperty('first_name') && errors.first_name }" maxlength="100" type="text"
                                       v-model="form.first_name" :placeholder="$t('enter your first name')"/>
                                <error-message :message="$t(errors.first_name)"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="text-capitalize">{{ $t("Last name") }}</label>
                                <input class="form-control" :class="{ 'has-error':errors.hasOwnProperty('last_name') && errors.last_name }" maxlength="100" type="text"
                                       v-model="form.last_name" :placeholder="$t('enter your last name')"/>
                                <error-message :message="$t(errors.last_name)"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Password</label>
                        <input class="form-control" :class="{ 'has-error':errors.hasOwnProperty('password') && errors.password.length }"
                               type="password" v-model="form.password" :placeholder="$t('Password')"/>
                        <error-message :message="errors.password"/>

                    </div>

                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input class="form-control" :class="{ 'has-error':errors.hasOwnProperty('password_confirmation') && errors.password_confirmation.length }"
                               type="password" v-model="form.password_confirmation" :placeholder="$t('re-type password')"/>
                        <error-message :message="errors.password_confirmation"/>

                    </div>

                    <submit-button :click="signUp" :block="true" :loading="isLoading">Sign Up</submit-button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import SiteLogoSVG from "../components/SiteLogoSVG";

const LanguageSwitcher = () => import("../components/dropdown/LanguageSwitcher.vue");
import {mapActions, mapGetters} from 'vuex';
import {USER_LOGGED_IN} from "../constants/action-type";
import client from "../app/api/Client";

const ErrorMessage = () => import("../components/common/ErrorMessage.vue");

export default {
    computed: {
        ...mapGetters(['getUser']),
        token() {
            return this.$route.query.token;
        },
    },
    components: {
        SiteLogoSVG,
        ErrorMessage,
        LanguageSwitcher,
    },
    data() {
        return {
            form: {
                email: "",
                first_name: "",
                last_name: "",
                password: "",
                password_confirmation: "",

            },
            errors: {
                email: "",
                first_name: "",
                last_name: "",
                password: "",
                password_confirmation: "",

            },
            isLoading: false,
            errorMessage: ''
        }
    },
    methods: {
        ...mapActions([USER_LOGGED_IN]),
        signUp: function () {
            this.form.token = this.token;
            if (this.validateForm()) {
                this.isLoading = true;
                client().post('/onboard', this.form)
                    .then(({data}) => {
                        this.$toast.success(data.message);
                        this.$router.push({name: 'login'});
                        this.isLoading = false;
                    })
                    .catch(error => {
                        let errors = error.response.data.message;
                        if (typeof errors === 'object') {
                            this.errors = _helper.serializeValidationMessage(errors);
                        } else {
                            this.errorMessage = errors;
                            this.$toast.error(errors);
                        }
                    }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        validateForm() {
            this.errors.first_name = "";
            this.errors.last_name = "";
            this.errors.email = "";
            this.errors.password = "";
            this.errors.password_confirmation = "";

            if (_.isEmpty(this.form.first_name)) {
                this.errors.first_name = "The first name field is required.";
                return false;
            }

            if (this.form.first_name.length > 50) {
                this.errors.first_name = "The first name may not be greater than 50 characters.";
                return false;
            }

            if (_.isEmpty(this.form.last_name)) {
                this.errors.last_name = "The last name field is required.";
                return false;
            }

            if (this.form.first_name.length > 50) {
                this.errors.last_name = "The last name may not be greater than 50 characters.";
                return false;
            }

            if (this.form.email.length < 1) {
                this.errors.email = "Email field is required";
                return false;
            }

            if (!_helper.validateEmail(this.form.email)) {
                this.errors.email = "Please type a valid email address";
                return false;
            }

            if (this.form.password.length < 1) {
                this.errors.password = "Password field is required";
                return false;
            }

            if (this.form.password_confirmation.length < 1) {
                this.errors.password_confirmation = "Confirm password field is required";
                return false;
            }

            if (this.form.password.length < 8) {
                this.errors.password = "Password should be at least 8 latter";
                return false;
            }

            if (this.form.password !== this.form.password_confirmation) {
                this.errors.password_confirmation = "Passwords did not match";
                return false;
            }

            return true;
        },
        async getEmail() {
            this.isLoading = true;
            try {
                let {data: {data}} = await client().post(`onboard/email`, {
                    token: this.token
                });
                this.form.email = data.email;
                this.isLoading = false;

            } catch (error) {
                this.$toast.error(error.response.data.message);
                this.errorMessage = error.response.data.message;
            }

            this.isLoading = false;


        },
        hideErrorAlert() {
            this.errorMessage = '';
        }

    },
    mounted() {
        this.getEmail();
    }
}
</script>


